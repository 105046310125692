/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BrowserNotSupported from '@cleverrealestate/clever-components-v2/dist/components/BrowserNotSupported';
import './Layout.css';

const SkeletonLayout = ({
  children,
}) => {
  const [showBrowserSupport, setShowBrowserSupport] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
      const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
      setShowBrowserSupport(isIE);
    }
  }, []);

  return (
    <>
      {showBrowserSupport && <BrowserNotSupported onClose={() => setShowBrowserSupport(false)} />}
      <main>{children}</main>
    </>
  );
};

SkeletonLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SkeletonLayout;
