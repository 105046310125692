/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import HVE from '@cleverrealestate/clever-components-v2/dist/components/Forms/HVE';
import Footer from '@cleverrealestate/clever-components-v2/dist/components/Footer';
import NavBar from '@cleverrealestate/clever-components-v2/dist/components/NavBar';
import SEO from '../../components/Seo';
import SkeletonLayout from '../../components/SkeletonLayout';
import { useNewBlogNavItems } from '../../utils/newNavItems-utils';

const HomeValueEstimator = ({
  pageContext,
}) => {
  useUserIPGrabber();
  const {
    slug,
    meta_title,
    meta_description,
    no_index,
    image,
    contentTopic,
    contentType,
  } = pageContext;
  const menuItems = useNewBlogNavItems();
  const { footer } = useStaticQuery(
    graphql`
      {
        footer: cosmicjsFooter {
          metadata {
            sections {
              section {
                metadata {
                  links {
                    blank
                    label
                    url
                  }
                }
                title
              }
            }
            legal
          }
        }
      }
    `,
  );

  return (
    <SkeletonLayout>
      <SEO
        pathname={slug}
        title={meta_title}
        description={meta_description}
        noIndex={no_index}
        image={image.url}
        imageHeight={image.height}
        imageWidth={image.width}
        contentTopic={contentTopic}
        contentType={contentType}
      />

      <HVE
        NavBar={() => <NavBar menuItems={menuItems} />}
        Footer={() => <Footer content={footer.metadata} />}
      />
    </SkeletonLayout>
  );
};

HomeValueEstimator.propTypes = {
  pageContext: PropTypes.any,
};

HomeValueEstimator.defaultProps = {
  pageContext: {},
};

export default HomeValueEstimator;
